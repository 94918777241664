import React from 'react'
import {Link, graphql} from 'gatsby'
import Img from "gatsby-image"
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Nav from '../components/nav'
import Footer from '../components/footer'
import Arrow from '../assets/arrow.svg'
import PortableText from '../components/portableText'

import "../styles/tempProject.scss"

export const query = graphql`
  query extraProjectTemplateQuery($slug: String!){
    extraProject: sanityPortfolioExtraItem(slug: {current: {eq: $slug}}) {
      name
      id
      excerpt
      _rawDescription
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      collaborators {
        collaborators {
          name
          portfolioLink
        }
      }
      role
      links {
        linkTitle
        url
      }
      tools
      _rawProjectInformation
    }
  }
`
const OverviewSection = ({sectionTitle, content}) => {
  if (content.length === 0) {
    return null;
  }


  return (
      <>
          <h3>{sectionTitle}</h3>
          <ul>
            {content}
          </ul>
      </>
  )
}

const projectPage = props => {
  const { data } = props;
  console.log(data);

  const projectRole = data.extraProject.role.map((role, index)=>(
    <li key={index}>{role}</li>
  ));

  const projectTools = data.extraProject.tools.map((tool, index)=>(
    <li key={index}>{tool}</li>
  ));

  const projectCollaborators = data.extraProject.collaborators.map((collaborators, index)=>{
    if(collaborators.collaborators.portfolioLink){
      return(<a href={collaborators.collaborators.portfolioLink} target="_blank"><li>{collaborators.collaborators.name}<Arrow/></li></a>)
    } else {
      return(<li>{collaborators.collaborators.name}</li>)
    }
  });

  const projectLinks = data.extraProject.links.map((link, index)=>(
    <a href={link.url} target="_blank"><li>{link.linkTitle}<Arrow/></li></a>
  ));

  return (
    <Layout>
      <SEO title={data.extraProject.name + " | The Varun Khatri"}/>
      <Nav scrollPoint="#extraProjectHero"/>
      <div id="extraProjectHero">
        <div className="container">
          <Img fluid={data.extraProject.image.asset.fluid} className="projectImage"/>
          <div className="projectHead">
            <Link to="/"><div className="back"><Arrow/> Back</div></Link>
            <div className="projectHeader">
                <h1>{data.extraProject.name}</h1>
                <h2>{data.extraProject.excerpt}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="extraProjectBody container">
        <div className="projectSection projectOverview">
          <h2>Project Overview</h2>
          <PortableText blocks={data.extraProject._rawDescription}/>
          <OverviewSection sectionTitle="Role" content={projectRole}/>
          <OverviewSection sectionTitle="Tools" content={projectTools}/>
          <OverviewSection sectionTitle="Collaborators" content={projectCollaborators}/>
          <OverviewSection sectionTitle="Links" content={projectLinks}/>
        </div>
        <div className="projectMain">
          <div className="projectSection">
            <h2>Project Information</h2>
            <PortableText blocks={data.extraProject._rawProjectInformation}/>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default projectPage
